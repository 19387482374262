

.signin {
    &-container {
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        position: relative;
        background-size: cover;
    }

    &-logo {
        width: 75px;
        height: 75px;
        border-radius: 5px;
        margin: auto;
    }

    &-logo-wrapper {
        width: 100%;
        display: flex;
        margin-bottom: 3rem;
        justify-content: center;
        flex-shrink: 0;
        flex-direction: column;
    }

    &-content {
        width: 500px;
        height: 100%;
        margin: auto;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        padding: 6rem 3rem;
        position: relative;
        z-index: 10;
        
        input {
        border: 1px solid #d9d9d9;
        font-size: 1rem;
        height: 48px!important;
        line-height: 48px;
        }

        button {
          background: #0446ca;
          border-color: #0446ca;
          width: 100%;
          padding: 0.6rem;
          height: auto;
          font-size: 1rem;
          font-weight: 400;
          text-transform: uppercase;
          letter-spacing: 1px;
        }
    }
  

    &-cta {
        font-size: 1.58rem;
        font-weight: 300;
        text-align: center;
        color: #333;
        margin: 2rem 0 0 0;
    }

    &-gate {
        font-size: 1.2rem;
        font-weight: 300;
        text-align: center;
        color: #333;
        margin: 2rem 0 0 0;
    }
}