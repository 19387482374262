.share-buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.share-copy {
    display: flex;
    justify-content: center;
}

.share-title {
    display: flex;
    justify-content: center;
}

.share-button {
    display: inline-block;
    
    img { 
        width: 40px;
        height: "auto";
        border-radius: 100px;
        margin-right: 10px;
    }
}