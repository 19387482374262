.form-title {
    font-size: 1.3rem;
    font-weight: 400;
    // text-align: center;
    color: #434961;
}

.form-card {
    padding: 1rem 5rem;
}


// ANT Override
.ant-btn-primary {
    background-color: #{$primaryColor}!important;
    border-color: #{$primaryColor}!important;
}