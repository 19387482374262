.header {
  background-color: #20293a;
  height: 64px;
  padding: 0 50px;
  color: #fff;
  display: flex;
  justify-content: center;
}

.logo {
  height: 36px;
  margin: 10px 0 14px 0;
  width: auto;
  border-radius: 5px;
  display: inline-block;
  vertical-align: middle;
}

.logo-container {
  height: 64px;
  vertical-align: middle;
  line-height: 64px;
  margin-right: 15px;
}

.btn-header {
  line-height: 64px; 
  margin: 0 20px;
}