@import url(https://fonts.googleapis.com/css?family=Roboto:300,400);
.dashboard-chart {
  margin-top: 20px; }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1;
  font-family: Roboto, sans-serif;
  font-weight: 300; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

.main-container {
  height: calc(100vh - 64px);
  padding: 4rem 6rem 0 6rem;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto; }

.header {
  background-color: #20293a;
  height: 64px;
  padding: 0 50px;
  color: #fff;
  display: flex;
  justify-content: center; }

.logo {
  height: 36px;
  margin: 10px 0 14px 0;
  width: auto;
  border-radius: 5px;
  display: inline-block;
  vertical-align: middle; }

.logo-container {
  height: 64px;
  vertical-align: middle;
  line-height: 64px;
  margin-right: 15px; }

.btn-header {
  line-height: 64px;
  margin: 0 20px; }

.share-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px; }

.share-copy {
  display: flex;
  justify-content: center; }

.share-title {
  display: flex;
  justify-content: center; }

.share-button {
  display: inline-block; }
  .share-button img {
    width: 40px;
    height: "auto";
    border-radius: 100px;
    margin-right: 10px; }

.signin-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  background-size: cover; }

.signin-logo {
  width: 75px;
  height: 75px;
  border-radius: 5px;
  margin: auto; }

.signin-logo-wrapper {
  width: 100%;
  display: flex;
  margin-bottom: 3rem;
  justify-content: center;
  flex-shrink: 0;
  flex-direction: column; }

.signin-content {
  width: 500px;
  height: 100%;
  margin: auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 6rem 3rem;
  position: relative;
  z-index: 10; }
  .signin-content input {
    border: 1px solid #d9d9d9;
    font-size: 1rem;
    height: 48px !important;
    line-height: 48px; }
  .signin-content button {
    background: #0446ca;
    border-color: #0446ca;
    width: 100%;
    padding: 0.6rem;
    height: auto;
    font-size: 1rem;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px; }

.signin-cta {
  font-size: 1.58rem;
  font-weight: 300;
  text-align: center;
  color: #333;
  margin: 2rem 0 0 0; }

.signin-gate {
  font-size: 1.2rem;
  font-weight: 300;
  text-align: center;
  color: #333;
  margin: 2rem 0 0 0; }

.form-title {
  font-size: 1.3rem;
  font-weight: 400;
  color: #434961; }

.form-card {
  padding: 1rem 5rem; }

.ant-btn-primary {
  background-color: #0446ca !important;
  border-color: #0446ca !important; }

.data-card .ant-skeleton-paragraph {
  margin: 2.5px; }

